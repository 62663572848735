.match-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 2px solid hsl(0, 0%, 80%);
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .team-info {
    display: flex;
    align-items: center;
  }
  
  .team-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .team-name {
    font-weight:600;
    min-width: 200px;
    max-width: 200px;
    text-align: center;
  }
  
  .match-details {
    text-align: center;
  }
  
  .match-time {
    font-weight: bold;
    color: red;
  }
  .match-date {
    font-weight: bold;
  }
  
  .stadium {
    color: #666;
    max-width: 150px;
    overflow: hidden;
  }
  
@media screen and (max-width: 600px) {
    .team-name {
      min-width: 90px;
      font-size: 14px;
      max-width: 90px;
    }
    .team-logo {
        margin-right: 10px;
    }
      
      .match-time {
        font-size: 16px;
      }
    
      .match-date {
        font-size: 14px;
      }
    
      .stadium {
        font-size: 12px;
        max-width: 60px;
        overflow: hidden;
      }
    }
    
    @media screen and (max-width: 480px) {
      .team-name {
        font-size: 12px;
      }
    
      .match-time {
        font-size: 14px;
      }
    
      .match-date {
        font-size: 12px;
      }
    
      .stadium {
        font-size: 10px;
        max-width: 60px;
        overflow: hidden;
      }
    }